/*
Theme Name: Puchong Horizon
Author: the FFS team
Author URI: http://forefront.com.my/
Version: 1.0
License: for Forefront only
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: Puchong Horizon
*/

// Font weights
$light: 100;
$regular: 400;
$bold: 600;
$uppercase: uppercase;

// Base Font
$title-font-family: 'Berling', serif;
$title-font-weight: $regular;
$base-font-family: 'Now', sans-serif;
$base-font-weight: $regular;

// Fonts Color
$primary-color: #1d1d1d;
$secondary-color: #fbbc43;
$tertiary-color: #988cc0;
$darklight-color: #575757;
$lightblue-color: rgba(190,234,226,0.2);
$hover-color: #8f7655;
$dark-color: #2c2a27;
$gold-color: #c29d6d;
$white-color: #fff;
$red-color: #ff0000;
$orange-color: #ed9b15;
$lightorange-color: #fcf8ef;

// Headings
$header-font-weight: $bold;


@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

.box { @include border-radius(10px); }


