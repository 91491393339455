/** ============================================================================
 * @base Mixins
 * ========================================================================== */

/* Responsive Helpers
/* -------------------------------------------------------------------------- */
$breakpoint-mobile-sm: 320px;
$breakpoint-mobile: 568px;
$breakpoint-tablet-sm: 768px;
$breakpoint-tablet: 1024px;
$breakpoint-desktop-sm: 1200px;

@mixin on-mobile-sm {
  @media screen and (max-width: $breakpoint-mobile-sm) { @content; }
}

@mixin on-mobile {
  @media screen and (max-width: $breakpoint-mobile) { @content; }
}

@mixin on-tablet-sm {
  @media screen and (max-width: $breakpoint-tablet-sm) { @content; }
}

@mixin on-tablet {
  @media screen and (max-width: $breakpoint-tablet) { @content; }
}

@mixin on-desktop-sm {
  @media screen and (max-width: $breakpoint-desktop-sm) { @content; }
}

@mixin on-height-sm {
  @media screen and (max-height: $breakpoint-tablet-sm) { @content; }
}

