form {
    width: 100%;
    margin: 0px auto;

    .row {
        margin-bottom: 0px;
        padding-bottom: 10px;
    }
}
.form-label {
    text-align: left;
    label {
        font-family: $base-font-family;
        font-size: 0.9em;
        font-weight: 400;
        letter-spacing: 1px;
        color: $white-color;
    }
    span {
        color: $red-color;
    }
}
.form-control {
    font-family: $base-font-family;
    font-size: 0.9em;
    color: $white-color;
    background: transparent;
    border: none;
    border: 2px solid $secondary-color;
    border-radius: 0;
    box-shadow: none;
}
.form-group {
    margin-bottom: 10px;
}
textarea.form-control {
    background: white;
    border: 2px solid $secondary-color;
}

.form-child-padd {
    div {
        padding: 0px 5px;
    }
}

.form-footer {
    padding-right: 5%;
}

/* ======================
	Checkboxes and radios
   ====================== */
.form-check {
    font-family: $base-font-family;
    position: relative;
    display: block;
    margin-bottom: .5rem;
  
    &.disabled {
      .form-check-label {
        color: $darklight-color;
      }
    }
}
  
.form-check-label {
    padding-left: 2rem;
    margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-input, input.form-check-input {
    position: absolute;
    margin-top: .25rem;
    margin-left: -2rem;
    cursor: pointer;
}

/* ======================
	Radios and checkboxes on same line
   ====================== */
.form-check-inline {
    display: inline-block;
    margin-right: 1.5rem;

    .form-check-label {
        vertical-align: middle;
        cursor: pointer;
    }
}


/* ======================
	Custom Checkbox
   ====================== */
$checkbox-size: 20px;
$checkbox-gap: 10px;
$checkbox-weight: 2px;
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

.checkbox-group{
  position: relative;
  width: 100%;
  margin: 0px auto;
  padding-left: 30px;

  :disabled{
      padding:60px;
      cursor:auto !important;
  }

  label{
    width: 100%;

    text-align: left;

    cursor: pointer;

    input{
      display: block;
      position: absolute;
      top: 10px;
      left: 4px;
      opacity: 0;
      pointer-events: none;
    }

    input[type=checkbox]{
        margin-left: 0px;
    }

    & > span{
      display: block;
      float: left;

      span{
        color: $primary-color;
        text-decoration: underline;
      }
    }

    span:nth-of-type(2){
      width: calc( 100% - #{$checkbox-size + $checkbox-gap} );
    }
  }
    label[disabled]{
        cursor:auto;
        color:$darklight-color;
    }

  &.tac{
    label ~ p{
      text-align: left;

      margin: 0;
      margin-left: 30px;

      span{
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}

.custom-checkbox{
  position: relative;
  margin-left: -30px;
  margin-right: 10px;

  width: $checkbox-size;
  height: $checkbox-size;

  &:before, &:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );

    width: 100%;
    height: 100%;

    transition: all 300ms $easeOutQuart;
  }

  &:before{
    width: 100%;
    height: 100%;
    border: 2px solid $white-color;
  }

  &:after{
    background: $white-color;

    outline: 2px solid $primary-color;
    outline-offset: -4px;

    opacity: 0;
    transform: translate(-50%, -50%) scale(0, 0);
    // background-clip: content-box;
  }

  input:checked + &{
    &:before{ transform: translate(-50%, -50%) scale(0.2, 0.2); }
    &:after{
      opacity: 1;
      transform: translate(-50%, -50%) scale(1, 1);
    }
  }
  input:disabled+ &{
    &:before{
        width: 100%;
        height: 100%;
        border: 2px solid $darklight-color;
        cursor: auto;
      }
  }
}



/* ======================
	Form Terms
   ====================== */
.form-terms {
    margin-bottom: 0px;
    label {
        font-size: 0.8em;
        white-space: normal;
        color: $white-color;
    }
    label a {
        color: $secondary-color;
    }
}


/* ======================
	Submit
   ====================== */
input[type="submit"] {
    background-color: $primary-color;
    font-family: $base-font-family;
	font-size: 1.2em;
    color: $secondary-color;
    padding: 8px 40px;
}

.form-check-group{
    padding-top: 5px;
    padding-bottom: 5px;
}

option{
    color:$primary-color;
}