/* ======================
	Navigation
   ====================== */
nav {
	width: 100%;
	background: rgba($orange-color, 1);
	display: flex;
	position: relative;
	top: 0px;
	left: 0px;
	z-index: 30;
	@include on-mobile{
		position: absolute;
	}
}
.nav-logo {
	width: 25%;
	text-align: center;
	padding: 10px 20px;
	@include on-mobile-sm{
		padding: 0px 20px;
	}
	@include on-mobile{
		padding: 10px 30px;
	}
	a {
		display: inline-block;
	}
	img, svg {
		width: 180px;
		height: auto;
		vertical-align: middle;
		max-height:55px;
		@include on-mobile-sm{
			width: 150px;
		}
	}
	svg path {
		fill: $white-color;
	}
}
.mainMenu {
	font-family: $base-font-family;
	text-transform: uppercase;
	text-align: right;
	width: 75%;
	display: block;
	display: flex;
	margin: 0px auto;
	position: relative;
	align-items: center;
	justify-content: flex-end;
	@include on-tablet-sm {
		width: 100%;
		background: rgba($secondary-color, 1);
		border-bottom: 2px solid $secondary-color;
		position: absolute;
		top: 100%;
		flex-direction: column;
		align-items: flex-end;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
	}
	@include on-mobile{
		display:block;
		left:0;
		right:0;
	}

	ul {
		position: relative;
		padding-right: 30px;
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
		@include on-tablet-sm {
			padding-right: 10px;
			flex-direction: column;
			align-items: flex-end;
		}
	}
	ul li {
		display: inline-block;
	}
	ul li a {
		font-size: 1em;
		color: $white-color;
		display: block;
		position: relative;
		padding: 15px 20px;
		transition: all 0.3s ease;
		@include on-mobile{

		}
	}
}
nav ul li a:hover {
	color: $primary-color;
}


/* ======================
	Menu Button
   ====================== */
.menuBtn {
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
	text-decoration: none;
	color: $primary-color;
	border: 1px solid $primary-color;
	position: absolute;
	top: 30px;
	right: 30px;
	transition:all .2s ease-out;
	z-index: 35;
	@include on-mobile{
		top: 18px;
		right:25px;
	}
	@include on-mobile-sm{
		top: 13px;
		right:25px;
	}
}
.menuBtn i {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	margin: auto;
	display: inline-block;
	width: 18px;
	height: 2px;
	background: $primary-color;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	transition:all .2s ease-out;
	-webkit-transition:all .2s ease-out;
	-moz-transition:all .2s ease-out;
	-ms-transition:all .2s ease-out;
}
.menuBtn i::before, .menuBtn i::after {
	content:'';
	width: 18px;
	height: 2px;
	background: $primary-color;
	position: absolute;
	left:0;
	transition:all .2s ease-out;
	-webkit-transition:all .2s ease-out;
	-moz-transition:all .2s ease-out;
	-ms-transition:all .2s ease-out;
}
.menuBtn i::before {
	top: -6px;
}
.menuBtn i::after {
	bottom: -6px;
}
.menuBtn.active, .menuBtn:hover {
	border: 1px solid $primary-color;
}
.menuBtn:hover i, .menuBtn:hover i::before, .menuBtn:hover i::after {
	background: $primary-color;
}
.menuBtn.active i {
	background: none;
}
.menuBtn.active i::before {
	background: $primary-color;
	top:0;
	-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
			transform: rotateZ(45deg);
}
.menuBtn.active i::after {
	background: $primary-color;
	bottom:0;
	-webkit-transform: rotateZ(-45deg);
		-moz-transform: rotateZ(-45deg);
		-ms-transform: rotateZ(-45deg);
		-o-transform: rotateZ(-45deg);
			transform: rotateZ(-45deg);
}



/* ======================
	Cross Button
   ====================== */
.crossBtn {
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
	text-decoration: none;
	color: $primary-color;
	position: relative;
	z-index: 15;
}
.crossBtn i {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 10px;
	margin: auto;
	display: inline-block;
	width: 18px;
	height: 2px;
	transition:all .2s ease-out;
	-webkit-transition:all .2s ease-out;
	-moz-transition:all .2s ease-out;
	-ms-transition:all .2s ease-out;
}
.crossBtn i::before, .crossBtn i::after {
	content:'';
	width: 20px;
	height: 2px;
	background: $primary-color;
	position: absolute;
	left:0;
	transition:all .2s ease-out;
	-webkit-transition:all .2s ease-out;
	-moz-transition:all .2s ease-out;
	-ms-transition:all .2s ease-out;
}
.crossBtn i::before {
  top:0;
  -webkit-transform: rotateZ(45deg);
     -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
       -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.crossBtn i::after {
  bottom:0;
  -webkit-transform: rotateZ(-45deg);
     -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
       -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}


