/* ======================
	Home
   ====================== */
.home {
    width: 100vw;
    height: 100vh;
    position: relative;

    #canvas{
        z-index:1;
        position:absolute;
        width: 100vw;
        height: 100vh;
        @include on-mobile{
            display:none;
        }
    }
    .introdesk{
        display:block;
        @include on-mobile{
            display:none;
        }
    }
    .intromobile{
        display:none;
        @include on-mobile{
            display:block;
        }
    }

    .waves-bg {
        width: 100%;
        height: 100%;
        background-image: url("../images/waves-bg3.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 100%;
        position: absolute;
    }
    .focuspoint:before{
        @include on-mobile{
            background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        }
        @include on-tablet-sm {
            content: "";
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        @include on-mobile{
            background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        }
    }
    .home-logo {
        display: block;
        position: absolute;
        z-index:99;
        top: 50%;
        left: 5%;
        transform: translateY(-50%);
        @include on-mobile{
            left: 0;
            right: 0;
            top: auto;
            bottom: -15vh;
        }
        @include on-tablet {
            left: 1%;
            z-index: 10;
        }
        img {
            display: block;
            margin: 10px auto;
            @include on-tablet {
                max-width: 80%;
            }
        }
    }
}

/* ======================
	Intro
   ====================== */
.intro {
    height: 100vh;
    position: relative;
    @include on-tablet-sm {
        height: 50vh !important;
        margin-bottom: 25vh;
    }
    @include on-mobile{
        margin-bottom:50vh!important;
        // padding-top:16vh;
    }
    @include on-mobile-sm{
        margin-bottom:50vh!important;
    }

    #canvas{
        z-index:1;
        position:absolute;
        width: 100vw;
        height: 100vh;
        @include on-mobile{
            display:none;
        }
    }

    .waves-bg {
        width: 100%;
        height: 100%;
        background-image: url("../images/waves-bg4.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 100%;
        position: absolute;
        opacity:0.5;
        display:none;
        @include on-mobile{
            display:block;
        }
    }
    .focuspoint {
        @include on-tablet-sm {
            position: relative;
        }
    }
    .introdesk{
        display:block;
        @include on-mobile{
            display:none;
        }
    }
    .intromobile{
        display:none;
        @include on-mobile{
            display:block;
        }
    }
    .content-box {
        white-space: nowrap;
    }
    .content-col  {
        width: 50%;
        display: inline-block;
        position: relative;
        padding: 0px 30px;
        white-space: normal;
        vertical-align: top;
    }
    .content-col:nth-last-child(2):before {
        content: "";
        width: 1px;
        height: 100%;
        background: $secondary-color;
        position: absolute;
        right: 0;
        top: 0;
    }
    .content-col .title {
        display: block;
    }
    .desc-box {
        width: 30%;
        text-align: right;
        left: auto;
        right: 5%;
        @include on-tablet {
            width: 40%;
        }
        @include on-tablet-sm {
            width: 80%;
            text-align: left;
            position: relative;
            top: 0;
            right: 0;
            transform: none;
        }
    }
    .title {
        display: block;
        opacity: 0;
        @include on-tablet {
            opacity: 1;
        }
        @include on-tablet-sm {
            float: none;
        }
    }
    p {
        font-size: 1.1em;
        opacity: 0;
        @include on-tablet {
            opacity: 1;
        }
    }
    .white{
        color:$white-color;
        @include on-mobile{
            color:$primary-color;
        }
    }
}

/* ======================
	Masterplan
   ====================== */
.masterplan {
    background: $lightorange-color url("../images/waves-bg5.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin: 0px;
    .row {
        position: relative;
        padding: 0px 50px;
        display: flex;
        @include on-tablet-sm {
            display: block;
            padding: 0px;
        }
        @include on-mobile {
            padding: 0px 35px;
        }
    }
    .middle {
        @include on-tablet-sm {
            position: relative;
            top: 0;
            transform: none;
        }
    }
    h5 {
        font-family: $base-font-family;
        font-size: 1.3em;
        position: relative;
        margin-bottom: 20px;
    }
    h5:after {
        content: "";
        width: 50%;
        height: 1px;
        background: $primary-color;
        position: absolute;
        left: 0;
        bottom: -8px;
    }
    .content-box {
        @include on-mobile {
            width: 100%;
            margin-bottom: 0;
        }
    }
    .legend {
        margin-top: 30px;
    }
    .legend-box {
        width: 25px;
        height: 25px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: bottom;
    }
    .legend-box1 {
        @extend .legend-box;
        background-color: $secondary-color;
    }
    .legend-box2 {
        @extend .legend-box;
        background-color: $tertiary-color;
    }
    .img-responsive {
        position:relative;
        @include on-height-sm {
            max-width: 80%;
        }
        @include on-mobile {
            max-width: 100%;
        }
        img{
            width:100%;
            height:auto;
        }
        a{
            cursor: pointer;
        }
    }
    .mapSvg{
        position: absolute;
        width:100%;
        height:auto;
    }
}

/* ======================
	Location Map
   ====================== */
.location {
    background: $lightorange-color url("../images/waves-bg5.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    position: relative;
    margin: 0px;
    @include on-mobile{
        padding: 5vh 0;
    }
    .row {
        position: relative;
        padding-left: 50px;
        padding-right: 50px;
        display: flex;
        @include on-tablet-sm {
            width: calc(80% + 30px);
            margin: 0px auto;
            padding: 0px;
            display: block;
        }
        // @include on-mobile {
        //     padding-left: 10px;
        //     padding-right: 10px;
        // }
    }
    .middle {
        @include on-tablet-sm {
            position: relative;
            top: 0;
            transform: none;
        }
    }
    h5 {
        font-family: $base-font-family;
        position: relative;
        margin-bottom: 20px;
    }
    h5:after {
        content: "";
        width: 50%;
        height: 1px;
        background: $primary-color;
        position: absolute;
        left: 0;
        bottom: -8px;
    }
    .legend {
        margin-top: 30px;
        li {
            font-size: 0.9em;
            width: 30%;
            display: inline-block;
            margin-bottom: 10px;
            vertical-align: top;
        }
        li img {
            margin-right: 5px;
        }
    }
    .enlarge {
        font-family: $base-font-family;
        a {
            background: $secondary-color;
            color: $white-color;
            padding: 5px 30px 8px;
            transition: all ease 0.3s;
        }
        a:hover {
            background: $primary-color;
        }
    }
    .distance {
        p {
            margin-top: 20px;
            margin-bottom: 5px;
        }
        ul {
            width: 27%;
            display: inline-block;
            margin-right: 2%;
            vertical-align: top;
        }
        li {
            font-size: 0.8em;
        }
        @include on-tablet {
            ul {
                width: 43%;
                margin-right: 3%;
            }
        }
        @include on-tablet-sm {
            ul {
                width: 100%;
                margin-right: 0%;
            }
        }
    }
    .img-responsive-cont{
        @include on-height-sm {
            margin-right: 20%;
        }
        @include on-mobile {
            margin-right: 0;
        }
    }
    .img-responsive {
        width:100%;
        height:auto;
        // @include on-height-sm {
        //     max-width: 80%;
        // }
        // @include on-mobile {
        //     max-width: 100%;
        // }
    }
    .icons{
        float:left;
        display:block;
        position: relative;
        margin: 15px 5px;
    }
    .icons-text{
        float: left;
        display: block;
        position: relative;
        margin: 20px 9px;
    }
}

/* ======================
	Terms
   ====================== */
.terms {
    margin: 0px;
    li {
        margin-bottom: 15px;
        padding-left: 20px;
    }
    li span {
        display: inline-block;
        margin-left: -20px;
        margin-right: 3px;
    }
}

/* ======================
	Registration
   ====================== */
.registration {
    background: linear-gradient(to bottom, rgba(79,72,61,1) 0%,rgba(55,50,42,1) 100%);
    position: relative;
    margin: 0 0 10px 0;
    .waves-bg {
        width: 100%;
        height: 100%;
        background-image: url("../images/waves-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
    }
    .content-box {
        margin: 0px auto;
        padding-bottom: 0px;
        p {
            color: $white-color;
        }
    }
    .title {
        color: $secondary-color;
    }
    dl {
        white-space: normal;
    }
    dt {
        width: 4%;
    }
    dd {
        width: 95%;
    }
    a:hover {
        color: $secondary-color;
        text-decoration: underline;
    }
}


/* ======================
	Thank you
   ====================== */
.thank-you {
    width: 100%;
    height: 100vh;
    background-image: url("../images/waves-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -100px;
    .content-box {
        margin: 0px auto;
        padding-bottom: 0px;
        float: none;
    }
}

/* ======================
	Amimsition Overlay
   ====================== */
.animsition-overlay-slide{
    background-color: $orange-color !important;
}