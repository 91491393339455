@font-face {
    font-family: 'Now';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/now-bold.ttf') format('truetype'),
         url('../fonts/now-bold.woff') format('woff'),
         url('../fonts/now-bold.woff2') format('woff2'),
         url('../fonts/now-bold.otf') format('opentype');
}
  
@font-face {
    font-family: 'Now';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/now-regular.ttf') format('truetype'),
         url('../fonts/now-regular.woff') format('woff'),
         url('../fonts/now-regular.woff2') format('woff2'),
         url('../fonts/now-regular.otf') format('opentype');
}

@font-face {
    font-family: 'Berling';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('../fonts/berling-bold.ttf') format('truetype'),
         url('../fonts/berling-bold.woff') format('woff'),
         url('../fonts/berling-bold.woff2') format('woff2');
}
  
  

