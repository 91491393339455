/* ======================
	Show/Hide mobile
   ====================== */
.show-m {
    display: none;
    @include on-tablet-sm {
        display: block;
    }
}
.hide-m {
    display: block;
    @include on-tablet-sm {
        display: none;
    }
}


/* ======================
	Waves
   ====================== */
.waves {
    width: 100vw;
    height: 30vh;
    position: absolute;
    bottom: 0;
    left: 0;
}
#waves1, #waves2, #waves3, #waves4 {
    @extend .waves;
}
#waves2 {
    bottom: 30px;
}
#waves3 {
    bottom: 20px;
}
#waves4 {
    bottom: 50px;
}
#waves2 {
    @include on-mobile {
        bottom: -130px;
    }
}

/* ======================
	Scroll Button
   ====================== */
.scroll-icon {
    position: relative;
    margin: 30px auto;
    @include on-tablet {
        display: none;
    }
}
.scroll-btn {
    width: 4px;
    height: 8px;
    border: 1px solid $primary-color;
    border-radius: 15px;
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    margin: auto;
}

/* ======================
	Slide Button
   ====================== */
.slide-icon {
    text-align: center;
    position: relative;
    margin: 30px auto;
    display: none;
    @include on-tablet {
        display: block;
    }
}
.slide-center {
    position: relative;
    display: inline-block;
}
.slide-btn {
    position: absolute;
    top: 9px;
    right: 5px;
}

/* ======================
	More Button
   ====================== */
.more {
    font-family: $base-font-family;
    margin-top: 25px;
    a {
        background: $secondary-color;
        color: $white-color;
        padding: 5px 30px 8px;
        transition: all ease 0.3s;
    }
    a:hover {
        background: $primary-color;
    }
}


/* ======================
	Border-Box
   ====================== */
.border-box {
    background: $lightblue-color;
    border: 4px solid $secondary-color;
    padding: 20px;
    position: relative;
}
.border-box-inner {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    position: absolute;
    left: 3px;
    right:  3px;
    top: 3px;
    bottom: 3px;
    border: 1px solid $secondary-color;
    z-index: -1;
}

/* ======================
	Register Button
   ====================== */
.regBtn {
    font-family: $base-font-family;
    display: block;
}
.regBtn a, .formBtn {
    font-family: $base-font-family;
    background: $secondary-color;
    font-size: 1.2em;
    color: $white-color;
    margin: 5px 0px 10px;
    padding: 5px 20px;
    display: inline-block;
    border-radius: 5px;
    transition: all ease 0.3s;
}
.regBtn a:hover, .formBtn:hover {
    background: $tertiary-color;
}


/* ======================
	FFS Logo
   ====================== */
.ffs {
    margin: 0px !important;
}


/* ======================
	Modal
   ====================== */
.modal {
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }
    ul {
        list-style-type: disc;
        padding-left: 20px;
    }
    .btn-primary {
        background-color: $secondary-color;
        border-color: $secondary-color;
        border-radius: 0;
        transition: all ease 0.3s;
    }
    .btn-primary:hover {
        background-color: $primary-color;
        border-color: $primary-color;
    }
}
.modal-xl {
    width: 100%;
}