
/* =Tablet landscape
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
}

/* =Tablet portrait
--------------------------------------------------------------------------------------------------------*/
@media only screen and ( max-width: 768px ) {
}

/* =Mobile 568
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 568px) {
}

/* =Mobile 320
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 320px) {
}


/* =Mobile Landscape 568
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 568px) and (orientation : landscape) {
  /* ======================
	  Navigation
	 ====================== */
}

/* =Mobile Landscape 768
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 768px) and (orientation : landscape) {
}


