html, body {
	width: 100%;
	height: 100%;
}
.wrapper {
	width: 100%;
	min-height: 100%;
	height: auto;
	position: relative;
	overflow-x: hidden;
}
h1, h2, h3, h4, h5 {
	font-family: $base-font-family;
	letter-spacing: 1px;
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
}
h1 {
	font-size: 4em;
}
h2 {
	font-size: 3em;
}
h3 {
	font-size: 2.5em;
}
h4 {
	font-size: 2em;
}
h5 {
	font-size: 1.5em;
}
.font-smooth {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
b, strong {
	text-shadow: 0px 0px 0px $primary-color;
}
p, ul {
	font-family: $base-font-family;
	font-size: 1em;
	color: $primary-color;
}
a {
	text-decoration: none;
	color:white;
}
a:hover, a:focus {
	text-decoration: none;
}
.overflowHide {
	overflow: hidden;
}

dl {
	width: 100%;
	font-family: $base-font-family;
	font-size: 1em;
}
dt {
	width: 20%;
	display: inline-block;
	margin-bottom: 15px;
	vertical-align: top;
}
dd {
	width: 75%;
	display: inline-block;
	margin-bottom: 15px;
	padding-left: 15px;
	vertical-align: top;
}

.red {
	color: $red-color;
}



/* ======================
	Content
   ====================== */
.middle {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}
.fixed {
	position: fixed;
}

.inline-b {
	display: inline-block;
}

.title {
	text-transform: uppercase;
	position: relative;
	@include on-desktop-sm {
		font-size: 2.5em;
	}
	@include on-tablet {
		font-size: 2em;
	}
	@include on-mobile {
		font-size: 2em;
	}
}
.float-none {
	float: none;
}

.content-container {
	width: 100%;
	position: relative;
}
.content-box {
	width: 80%;
	display: block;
    margin: 30px auto;
    position: relative;

	p, ul {
		font-size: 1.1em;
		white-space: normal;
	}
	@mixin on-mobile {
		margin: 0 auto;
	}
}
.content-row {
	margin: 0px auto;
}

.col-padd-0 {
	padding: 0px;
}
.col-padd-1 {
	padding: 0px 10px;
}


.mobile-item {
	display: none;
}




/* ======================
	Footer
   ====================== */
$footer-height: 170px;
$smaller-font: 0.9em;
$smallest-font: 0.7em;

.padd-section {
	padding-top: 120px;
	padding-bottom: 50px;
	@include on-mobile {
		padding-top: 60px;
	}
}
.padd-footer {
	padding-bottom: $footer-height;
}

footer {
	width: 100%;
	// height: $footer-height;
	height:50px;
	// min-width: 100%;
	background-color: $secondary-color;
	// margin-top: -$footer-height;
	margin-top: -45px;
	padding: 0px 30px;
    clear: both;
	position: relative;
	@include on-mobile {
		height: auto;
	}

	p {
		letter-spacing: 1px;
		margin-bottom: 10px;
		display: inline-block;
	}
}
.footer-container {
	width: 70%;
	margin: 0px auto;
	padding-bottom: 30px;
}
.footer-col {
	margin: 0px;
}
.footer-row {
	display: block;
	img {
		max-width: 100%;
		display: inline-block;
		margin: 0px;
		@include on-mobile {
			margin: 0px;
		}
	}
}
.footer-logo {
	height: 100%;
	@include on-mobile {
		width: 50%;
		float: none;
		margin: 0px auto;
		padding-top:15px;
	}
}
.footer-copy {
	height:  100%;
	@include on-mobile {
		width: 100%;
		text-align: center;
		padding-left: 0px;
		padding-right: 0px;
		float: none;
	}
}
.footer-support {
	font-family: $base-font-family;
	.footer-row {
		@include on-mobile{
			min-height: 80px;
		}
	}
	.footer-link {
		margin: 14px 0;
		@include on-mobile{
			margin-bottom:0;
		}
	}
	a {
		text-transform: uppercase;
		font-size:12px;
		letter-spacing: 1px;
		color: $white-color;
		display: inline-block;
		margin: 0px 10px;
		@include on-tablet-sm {
			font-size: $smaller-font;
		}
		@include on-mobile {
			font-size: $smallest-font;
			margin: 0px 10px;
		}
	}
	a:last-child {
		margin-right: 0px;
	}
	p {
		font-size:0.9em;
		font-weight: 400;
		color: $white-color;
		display: inline-block;
		margin-left: 20px;
		@extend .font-smooth;
		@include on-tablet-sm {
			font-size: $smaller-font;
			width: 100%;
			margin-top: 5px;
			margin-left: 0;
		}
	}
	img {
		margin: 0px;
		margin-top: -3px;
		@include on-mobile {
			width: 5px;
		}
	}
}







